import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from 'webhart-base/SEO'
import { ContentComponent } from './CMSComponents'
import Layout from '../Layout'

import { css } from '@emotion/core'
import { Hero, Section, Container, TitleCaption } from '../../webhart-base'
import { colors, gradients } from '../../webhart-base/utils/style'

export const PostTemplate = ({ title, image, date, children, CMSPreview }) => {
  return (
    <>
      {/* <ImageComponent {...image} /> */}
      <span>{date}</span>
      <ContentComponent>{children}</ContentComponent>
    </>
  )
}

PostTemplate.defaultPropTypes = { CMSPreview: false }

PostTemplate.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  html: PropTypes.string,
  image: PropTypes.object,
  CMSPreview: PropTypes.bool,
}

const PostPage = ({ data }) => {
  const post = data.post
  return (
    <Layout>
      <SEO />
      <Hero
        size={100 / 3}
        image={post.frontmatter.image.childImageSharp.fluid}
        overlay={`
          background: ${gradients.darkBlue};
          opacity: ${2 / 3};
          mix-blend-mode: multiply;
        `}
        color={colors.orange}
        css={css`
          h1,
          p {
            text-shadow: 1px 1px 0 black;
          }
        `}
      >
        <h1>{post.frontmatter.title}</h1>
        <TitleCaption>- {post.frontmatter.date} -</TitleCaption>
      </Hero>
      <Section>
        <Container>
          <PostTemplate
            image={{
              alt: post.frontmatter.title,
              image: post.frontmatter.image,
            }}
            date={post.frontmatter.date}
            title={post.frontmatter.title}
          >
            {post.html}
          </PostTemplate>
        </Container>
      </Section>
    </Layout>
  )
}

PostPage.propTypes = {
  data: PropTypes.object,
}

export default PostPage

export const query = graphql`
  query PostTemplateQuery($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
